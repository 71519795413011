/* tslint:disable */
export default {
  // Menú Izquierda
  ALL: "Todos",
  MEN: "Hombres",
  WOMEN: "Mujeres",
  YOUTH_AND_KIDS: "Jóvenes/Niños",
  KIDS: "Jóvenes/Niños",
  SENIORS: "Mayores",
  DISABLED: "Discapacitados",
  FAMILIES: "Familias",
  LGBT: "LGBT+",
  PETS_ALLOWED: "Se permiten mascotas",
  ANYONE: "Cualquiera",
  // Autenticación
  SERVICE_PROVIDER_LOGIN: "Inicio de Sesión para Proveedores de Servicio",
  SERVICE_PROVIDER_SIGNUP: "Registro para Proveedores de Servicio",
  DISPLAY_NAME_SIGNUP: "Nombre del Servicio Comunitario",
  REQUIRED_INPUT_CTA: "Por favor ingresa {{value}}",
  WELCOME_SHELTER: "Bienvenido a Shelter App",
  WELCOME_BACK: "Bienvenido de nuevo.",
  WELCOME_BACK_WITH_USER: "Bienvenido {{value}}.",
  HOME: "Inicio",
  FORGOT_PASSWORD: "Olvidé la Contraseña",
  RESET_PASSWORD: "Restablecer Contraseña",
  OR_CREATE_AN_ACCOUNT: "O crear una cuenta",
  SIGN_IN: "INICIAR SESIÓN",
  SIGN_UP: "REGISTRARSE",
  CANCEL: "Cancelar",
  EMAIL: "Correo Electrónico",
  PASSWORD: "Contraseña",
  LOGOUT: "Cerrar Sesión",
  LOGOUT_THIS_ACCOUNT: "¿Cerrar sesión en esta cuenta?",
  SIGN_IN_WITH: "Iniciar sesión con",
  BY_CLICKING: "Al hacer clic en Registrarse, aceptas ",
  DONATION: "Donación",
  PRIVACY_POLICY: "Política de Privacidad",
  ACCEPT_TERM_OF_USE_AND_PRIVACY_POLICY:
    "Aceptar Términos de Uso y Política de Privacidad",
  PLEASE_ACCEPT_TERMS:
    "Por favor acepta los Términos y Privacidad para continuar.",
  SIGNED_OUT_SUCCESSFULLY: "Sesión cerrada exitosamente",
  PASSWORD_MIN_LENGTH:
    "La contraseña debe tener al menos {{value}} caracteres.",
  EMAIL_OR_PASSWORD_INVALID:
    "Por favor verifica tu Correo o Contraseña e intenta de nuevo.",
  OLD_PASSWORD_INVALID:
    "Por favor verifica tu contraseña actual e intenta de nuevo.",
  PASSWORD_NOT_MATCH:
    "La Nueva Contraseña no coincide. Por favor verifica e intenta de nuevo.",
  EMAIL_MUST_BE_A_VALID_EMAIL: "Por favor ingresa un correo válido.",
  REQUEST_RESET_PASSWORD_SUCCESSFULLY:
    "Por favor verifica tu correo para restablecer la contraseña",
  EMAIL_NOT_FOUND: "Correo no encontrado.",
  RESET_PASSWORD_SUCCESSFULLY: "Contraseña restablecida exitosamente.",

  // Servicio
  END_DATE_SHOULD_BE_GREATER:
    "La fecha de fin debe ser igual o mayor que la fecha de inicio",
  FLAG_AND_DONATE: "Marcar y Donar",
  IS_SHOW_CONTACT: "Por favor contacta este servicio para conocer los horarios",
  IS_ALLOWED_PETS: "Permitir mascotas en la propiedad",
  IS_SHOW_FLAG: "Mostrar Marca",
  IS_SHOW_DONATE: "Mostrar Donar",
  OWNED_BY: "Propiedad De",
  PLEASE_SELECT_AT_LEAST_ONE_SERVICE:
    "Por favor selecciona al menos un Servicio.",
  PLEASE_SELECT_AT_LEAST_ONE_CATEGORY:
    "Por favor selecciona al menos una Categoría.",
  PLEASE_ENTER_SERVICE_TYPE: "Por favor introduce la Descripción del Servicio",
  PLEASE_ADD_SCHEDULE: "Por favor añade un horario",
  ADDED_SUCCESSFULLY_AND_YOU_WANT_TO_CREATE_NEW:
    "Servicio añadido exitosamente. ¿Quieres añadir otro?",
  UPDATE_SERVICE_SUCCESSFULLY: "Servicio actualizado exitosamente",
  COMMUNITY_SERVICE_NAME: "Nombre del servicio comunitario",
  ADDRESS: "Dirección",
  ADDRESS_1: "Dirección 1",
  ADDRESS_2: "Dirección 2",
  ADD_TO_SCHEDULE: "Añadir al horario",
  HOLIDAYS_CLOSED_DAYS: "Días cerrados por festivos",
  UPDATE_SERVICE: "Actualizar Servicio",
  THIS_SERVICE_IS_NOT_APPROVED: "Este servicio está esperando aprobación",
  THIS_SERVICE_IS_APPROVED:
    "Este servicio está aprobado y visible para los usuarios",
  NO_SERVICES_ADDED: "No se añadieron servicios",
  ADDED_BY: "Añadido Por",
  PHONE: "Teléfono",
  WEBSITE: "Sitio Web",
  SERVICES: "Servicios",
  SERVICE_TYPE: "Tipo de Servicio",
  CATEGORIES: "Categorías",
  AGE_GROUP: "Grupo de Edad",
  SCHEDULE: "Horario",
  CLOSED_DAYS: "Días cerrados",
  COMMENT: "Comentario",
  EDIT_SERVICE: "Editar Servicio",
  DELETE_SERVICE: "Eliminar Servicio",
  ADDED_TO_FAVORITE: "Añadido a Mis Favoritos",
  REMOVED_TO_FAVORITE: "Eliminado de Mis Favoritos",
  NAME: "Nombre",
  SEARCH_SERVICE: "Buscar Servicio",
  NO_FAVORITES:
    "No se añadieron favoritos. Puedes añadir un servicio a favoritos haciendo clic en la estrella en la parte superior derecha de la página de detalles del servicio.",
  CATEGORY: "Categoría",
  CONTACT_SERVICE: "Contactar Servicio",
  NO_SERVICES_CLIENT:
    "No se encontraron resultados que coincidan con tus criterios en la pestaña {{value}}. Por favor elige otras Pestañas en la parte inferior o Cambia tu ubicación o Elige diferentes opciones en el Menú Lateral.",
  NO_MANAGE_SERVICE:
    "No se añadieron servicios. Por favor haz clic en Añadir Servicios en el Menú Lateral y luego enlace a",
  NO_SHELTER:
    "No hay servicios de albergue disponibles para actualizar la información de camas.",
  ACCEPT: "Aceptar",
  NO_DATA_SERVICES: "No se encontraron Servicios.",
  DESC_LESS_THAN_VALUE: "Descripción en menos de {{value}} caracteres.",
  CONNECT_WITH_US: "¿Conectar con este Servicio?",
  LAST_UPDATE_ON: "Última actualización en",
  OPEN_24H: "Abierto 24/7",
  TODAY_AT: "Hoy a",
  TOMORROW: "Mañana",
  YESTERDAY: "Ayer",
  LAST_WEEK: "la semana pasada",
  LOW_BARRIER: "Barrera baja",

  // Menú
  OPTIONS: "Opciones",
  OPEN_SERVICES: "Servicios Abiertos",
  TOP_KUDOS: "Máximos Elogios",
  MAP_VIEW: "Vista de Mapa",
  CRISIS_LINES: "Líneas de Crisis",
  MY_FAVORITES: "Mis Favoritos",
  ABOUT_SHELTER: "Acerca de la Aplicación Shelter",
  SHOW_TUTORIAL: "Mostrar Tutorial",
  GIVE_FEEDBACK: "Dar Retroalimentación",
  TERMS_OF_USE: "Términos de Uso",
  ADD_SERVICE: "Añadir Servicio",
  MANAGE_SERVICES: "Gestionar Servicios",
  MANAGE_FEEDBACK: "Gestionar Retroalimentación",
  UPDATE_SHELTER: "Actualizar Albergue",
  MANAGE_PROFILE: "Gestionar Perfil",
  MANAGE_APPROVALS: "Gestionar Aprobaciones",
  MANAGE_PERMS: "Gestionar Permisos",
  MANAGE_USERS: "Gestionar Usuarios",
  MANAGE_ACCESS: "Gestionar Acceso",
  CHANGE_PASSWORD: "Cambiar Contraseña",
  ENTER_NEW_PASSWORD: "Ingresar nueva contraseña",
  LOGIN_AND_SIGNUP: "Inicio de Sesión/Registro de Proveedor",

  // Cambiar Contraseña
  CONFIRM_PASSWORD: "Confirmar Contraseña",
  CURRENT_PASSWORD: "Contraseña Actual",
  NEW_PASSWORD: "Nueva Contraseña",

  // Actualizar Perfil
  UPDATE_PROFILE: "Actualizar Perfil",
  UPDATE_PROFILE_SUCCESSFULLY: "Perfil de Usuario Actualizado Exitosamente",

  // Mis Favoritos
  SEARCH_FAVORITES: "Buscar Favoritos",

  // CRISLINES
  SEARCH_LINES: "Buscar Líneas de Crisis",
  ADD_CRISIS_LINE: "Añadir Línea de Crisis",
  UPDATE_LINE: "Actualizar Línea",
  UPDATE_CRISIS_LINE: "Actualizar Línea de Crisis",
  CRISIS_LINE_INFORMATION: "Información de la Línea de Crisis",
  CRISIS_LINE_NAME: "Nombre de la línea de crisis",
  UPDATE_LINE_SUCCESSFULLY: "Línea de Crisis Actualizada Exitosamente",
  CHAT_WEB_LINK: "Enlace de Chat Web",
  AREA: "Área",
  HOURS: "Horas",
  DO_YOU_WANT_TO_DELETE_THIS: "¿Deseas eliminar este {{value}}?",
  ADDED_SUCCESSFULLY_AND_YOU_WANT_TO_CREATE_NEW_CRISLINES:
    "Línea de Crisis Añadida Exitosamente. ¿Quieres añadir otra?",

  // DBACK
  CONNECT_TO: "¿Conectar con {{value}}?",
  SENT_FEEDBACK_SUCCESSFULLY: "Retroalimentación Enviada Exitosamente",
  ARE_YOU_SURE_WANT_TO_ARCHIVE_THIS:
    "¿Estás seguro de que quieres archivar esto?",
  FEEDBACK: "Retroalimentación",
  SEND_FEEDBACK: "Enviar Retroalimentación",
  AVAILABLE_BEDS: "Camas Disponibles",
  NO_FEEDBACKS:
    "No tienes ninguna nueva retroalimentación. Por favor revisa la pestaña Archivo para retroalimentaciones pasadas.",
  SEARCH_FEEDBACK: "Buscar Retroalimentación",
  APP_FEEDBACK: "Retroalimentación de la App",
  SERVICE_FEEDBACK: "Retroalimentación del Servicio",
  ARCHIVE: "Archivo",
  MY_FEEDBACKS: "Mis Retroalimentaciones",

  // UBICACIÓN
  CHOOSE_CITY_ZIP_FOR_SERVICES: "Elige Ciudad/Código Postal para Servicios",
  CURRENT_LOCATION: "Ubicación Actual",
  SEARCH_CITY_NAME_OR_ZIP_CODE: "Buscar Nombre de Ciudad o Código Postal",
  NO_MATCHING_RESULTS_FOUND:
    "No se encontraron resultados coincidentes. Por favor ingresa una ubicación diferente.",
  CAN_NOT_GET_LOCATION:
    "No se puede obtener la ubicación actual. Ir a Configuración -> Abrir Ubicación",
  ADD_YOUR_LOCATION: "Añade tu ubicación",

  // GESTIONAR APROBACIONES
  SEARCH_APPROVALS: "Buscar Aprobaciones",
  APPROVE_SELECTED: "Aprobar Seleccionados",
  APPROVE_ALL: "Aprobar Todos",
  DELETE_SELECTED: "Eliminar Seleccionados",
  TUTORIALS: "Tutoriales de Shelter",
  APPROVED_SUCCESSFULLY: "Aprobado exitosamente",

  // USUARIO
  USER_PROFILE: "Perfil del Usuario",
  UPDATE_USER_SUCCESSFULLY: "Perfil del Usuario Actualizado Exitosamente",

  // PERMS
  APPROVED_USER_AS_ADMIN_SUCCESSFULLY: "Agregado {{value}} como Administrador.",
  REMOVED_USER_AS_ADMIN_SUCCESSFULLY: "Removido {{value}} como Administrador.",

  // FECHA
  SUNDAY: "Domingo",
  MONDAY: "Lunes",
  TUESDAY: "Martes",
  WEDNESDAY: "Miércoles",
  THURSDAY: "Jueves",
  FRIDAY: "Viernes",
  SATURDAY: "Sábado",
  PERMANENTLY_CLOSED: "Cerrado permanentemente",
  DATE_RANGE: "Rango de fechas",
  WEEKLY: "Semanal",
  MONTHLY: "Mensual",

  // MOSTRAR TUTORIAL
  I_AGREE: "Estoy de acuerdo",

  NETWORK_REQUEST_FAILED:
    "No se puede conectar al servidor. Por favor verifica tu red.",
  ROLE: "Rol",
  TOTAL_SERVICE: "Servicio Total",
  MINIMUM_TOTAL_SERVICE: "El servicio total mínimo fue {{value}}",
  CREATED_AT: "Creado En",
  LAST_LOGIN_METHOD: "Último método de inicio de sesión",
  LAST_LOGIN_AT: "Último inicio de sesión en",
  LAST_UPDATED_AT: "Última actualización en",
  USER_NEED_NOT_SIGNUP:
    "Los usuarios no necesitan Registrarse. Esto es solo para Proveedores de Servicio.",
  START_DATE: "Fecha de inicio",
  END_DATE: "Fecha de fin",
  START_TIME: "Hora de inicio",
  END_TIME: "Hora de fin",
  ADD_THIS_HEADER: "Añadir este Servicio al Encabezado",
  NEVER_EXPIRIES: "Nunca Expira",
  MANAGED_BY: "Gestionado Por",
  AVAILABLE_BEDS_LESS_THAN:
    "Las camas disponibles deben ser menos o iguales a las Camas Totales",
  CHECK_TIME_START: "La Hora de Fin debe ser mayor que la Hora de Inicio",
  PLEASE_TYPE_TOTAL_BEDS: "Por favor ingresa Camas Totales",
  TOTAL_BEDS: "CAMAS TOTALES",
  PLEASE_ENTER_COUNTRY: "Por favor ingresa País",
  ADDED_SUCCESSFULLY: "Servicio Añadido Exitosamente.",
  CRITICAL_SERVICES: "Servicios Críticos",
  CONTACT_GROUP: "Grupo de Contacto",
  SCHEDULE_TYPE: "Tipo de Horario",
  SELECT_DAY: "Seleccionar Día",
  NO_BEDS:
    "Información de camas no disponible en ningún servicio de albergue existente.",
  NO_ECYEH:
    "No hay servicios disponibles para actualizar la información de camas.",
  REPORT_SERVICE_INFO:
    "Haz clic aquí para Reportar Información Incorrecta o\n  Reclamar este Servicio",
  CITY: "ciudad",
  STATE: "estado",
  ZIP: "código postal",
  COUNTRY: "país",
  SERVICE_DETAILS: "detalles del servicio",
  CRITICAL_DESC: "Descripción de una frase",
  SOCIAL_MEDIA_LINKS: "Enlaces de Redes Sociales",
  SERVICE: "Servicio",
  MINIMUM_TOTAL_BEDS: "Las camas totales mínimas fueron {{value}}",
  MINIMUM_AVAILABLE_BEDS: "Las camas disponibles mínimas fueron {{value}}",
  EXPIRY_DATE: "Fecha de Expiración",
  EXPIRY_TIME: "Hora de Expiración",
  MANAGED_USERS: "Usuarios Gestionados",
  HOMELESS_LIAISONS: "Enlaces para Personas sin Hogar",
  HOMELESS_LIAISON: "Enlace para Personas sin Hogar",
  REGIONAL_COORDINATORS: "Coordinadores Regionales",
  ABOUT_ECYEH: "Acerca de la App",
  VOLUNTEER: "Voluntario",
  DONATE: "Donar",
  MANAGE_SUPPER: "Gestionar Super Usuarios",
  SEARCH_USERS: "Buscar Usuarios",
  CRISIS_LINE: "Línea de Crisis",
  SEARCH_LIAISONS: "Buscar Enlaces para Personas sin Hogar",
  EDIT_CRISIS_LINE: "Editar Línea de Crisis",
  CRISIS_LINE_DESC: "Descripción de la línea de crisis",
  ADD_LINE_SUCCESSFULLY: "Línea de Crisis Añadida Exitosamente.",
  YOU_REACHED_LIMIT_SERVICE:
    "Has alcanzado el Límite Máximo para añadir nuevos servicios. Por favor consulta con el Administrador de la App en ecyehappinfo@gmail.com para solicitar acceso para añadir más servicios.",
  TEXT: "TEXTO",
  LIAISON: "Enlace",
  LOCATION: "Ubicación",
  COUNTY: "Condado",
  SELECT_COUNTY: "Seleccionar Condado",
  POSITION: "Posición",
  DISTRICT: "DISTRITO",
  ADD_LIAISON: "Añadir Enlace",
  EDIT_LIAISON: "Editar Enlace",
  UPDATE_LIAISON: "Actualizar Enlace",
  LIAISON_NAME: "Nombre del enlace",
  LIAISON_TITLE: "Título del enlace",
  LIAISON_INFORMATION: "Información del Enlace",
  LIAISON_SUCCESSFULLY: "Enlace Actualizado Exitosamente",
  ADDED_SUCCESSFULLY_AND_YOU_WANT_TO_CREATE_NEW_LIAISON:
    "Enlace Añadido Exitosamente. ¿Quieres añadir otro?",
  ADD_REGIONS: "Añadir Región",
  EDIT_REGIONS: "Editar Región",
  UPDATE_REGION: "Actualizar Región",
  UPDATE_REGION_SUCCESSFULLY: "Región Actualizada Exitosamente",
  ADD_REGION_SUCCESSFUL: "Región Añadida Exitosamente",
  SELECT_REGION: "Seleccionar Región",
  REGION: "Región",
  REGIONS: "Regiones",
  COUNTIES: "Condados",
  SEARCH_COORDINATOR: "Buscar Coordinador",
  UPDATE_COORDINATOR: "Actualizar Coordinador Regional",
  EDIT_COORDINATOR: "Editar Coordinador Regional",
  ADD_COORDINATOR: "Añadir Coordinador Regional",
  ADD_ADDRESS: "Añadir Dirección",
  MOBILE_PHONE: "Teléfono Móvil",
  FAX_PHONE: "Teléfono de Fax",
  OFFICE_PHONE: "Teléfono de Oficina",
  EXTENSION: "Extensión",
  COORDINATOR: "COORDINADOR",
  REGIONAL_COORDINATOR: "Coordinador Regional",
  REPORT_TITLE: "Reportar Información Incorrecta o Reclamar este Servicio?",
  SENT_REPORT_SUCCESSFULLY: "Informe Enviado Exitosamente",
  SUBJECT: "Asunto",
  SEND: "ENVIAR",
  SERVICE_PROVIDER: "Proveedor de Servicio",
  YES: "Sí",
  NO: "No",
  CHATBOT_FAQ: "Preguntas Frecuentes del Chatbot",
  DESCRIPTION: "descripción",
  TITLE: "Título",
  MESSAGE: "Mensaje",
  PLEASE_INPUT_AT_LEAST_ONE_COORDINATOR:
    "Por favor ingresa al menos un Coordinador.",
  ADD_LIAISON_SUCCESSFULLY: "Enlace Añadido Exitosamente",
  UPDATE_LIAISON_SUCCESSFULLY: "Enlace Actualizado Exitosamente",
  ADD_COORDINATOR_SUCCESSFULLY: "Coordinador Añadido Exitosamente",
  UPDATE_COORDINATOR_SUCCESSFULLY: "Coordinador Actualizado Exitosamente",
  ATTACHMENTS: "ADJUNTOS",
  AVAILABLE_TOTAL_BEDS: "Camas Disponibles/Totales",
  USER: "Usuario",
  DELETE_LIAISON_SUCCESSFUL: "Este enlace fue eliminado exitosamente!",
  DELETE_REGION_SUCCESSFUL: "Esta región fue eliminada exitosamente!",
  DELETE_SERVICE_SUCCESSFUL: "Este servicio fue eliminado exitosamente!",
  DELETE_COORDINATOR_SUCCESSFUL: "Este coordinador fue eliminado exitosamente!",
  DELETE_USER_SUCCESSFUL: "Este usuario fue eliminado exitosamente!",
  DELETE_LINE_SUCCESSFUL: "Esta línea de crisis fue eliminada exitosamente!",
  SKIP: "OMITIR",
  WELCOME_ECYEH: "Introducción a Encontrando Tu Camino en PA",
  MILES: "Millas",
  CLOSED_TILL: "Cerrado hasta",
  CLOSED_UNTIL: "Cerrado hasta",
  OPEN_STILL: "Abierto hasta",
  CLOSED_TILL_NEXT: "Cerrado hasta próximo",
  SLIDE_1:
    "La pantalla de inicio de la App muestra una lista de servicios con información como Disponibilidad de Camas para Albergues, Tiempos de Apertura o Cierre, Distancia desde tu ubicación, etc. Siéntete libre de dar Elogios a los servicios que te gusten usando",
  SLIDE_2_1:
    "Puedes hacer clic en cualquier servicio en la Pantalla de Inicio para obtener información detallada incluyendo Direcciones de Autobús a ese servicio desde tu Ubicación. Puedes hacer clic en",
  SLIDE_2_2:
    "en la parte superior derecha de la pantalla de detalles para añadir ese servicio a Mis Favoritos.",
  SLIDE_3:
    "Las pestañas en la parte inferior de la Pantalla de Inicio te ayudarán a navegar a recursos como Alimentos, Albergue, Salud, Recursos y Educación. Cada pestaña tiene Sub Pestañas en la parte superior de ellas como Ropa, Higiene, Tecnología, etc., para ayudarte a estrechar tu búsqueda.",
  CLICK_ON: "Haciendo clic en",
  SLIDE_4:
    "en la parte superior de la Pantalla de Inicio te llevará al Menú de Opciones donde puedes filtrar servicios basados en Categoría como Jóvenes, LGBT, Mujeres,.. Servicios que están Abiertos Ahora, Vista de Mapa, Líneas de Crisis, Mis Favoritos, etc.",
  SLIDE_5:
    "Puedes hacer clic en Líneas de Crisis en el Menú de Opciones para alcanzar Líneas Directas Nacionales para apoyo emocional. También puedes buscar Líneas de Crisis o Servicios usando",
  SLIDE_6:
    "en la Pantalla de Inicio para servicios te ayudará a conectarte con el Administrador de la App o el Proveedor de Servicio para ese recurso.",
  CLICKING: "Haciendo clic",
  SLIDE_7_1:
    "Puedes ver servicios en Vista de Lista o Vista de Mapa e incluso buscar otra Ciudad o Código Postal usando",
  SLIDE_7_2: "en la parte superior de la Pantalla de Inicio.",
  SLIDE_8:
    "Pantalla de Inicio para interactuar con nuestro Chatbot Potenciado por IA para encontrar servicios con facilidad",
  ACCEPT_TERMS: "ACEPTAR TÉRMINOS",
  SLIDE_9:
    "Al hacer clic en Estoy de acuerdo, aceptas a la App Encontrando Tu Camino en PA, Inc's",
  FOOD: "Alimentos",
  SHELTER: "Albergue",
  HEALTH: "Salud",
  RESOURCES: "Recursos",
  EDUCATION: "Educación",
  WORK: "Trabajar",
  EMPLOYMENT: "Empleo",
  MEALS: "Comidas",
  FOOD_PANTRIES: "Despensas de Alimentos",
  EMERGENCY: "Emergencia",
  TRANSITIONAL: "Transicional",
  MEDICAL: "Médico",
  COUNSELING: "Asesoramiento",
  CLOTHES: "Ropa",
  HYGIENE: "Higiene",
  TECH: "Tecnología",
  ASSISTANCE: "Asistencia",
  PH_COMMUNITY_SERVICE_NAME: "Nombre de Organización sin Fines de Lucro",
  PH_LINE_NAME: "Ej: Línea Contra la Violencia Doméstica",
  PH_TEXT_NUMBER: "Número de texto",
  PH_DISTRICT: "Ej: Distrito Escolar del Valle de Allegheny",
  PH_LIAISON_TITLE: "Ej: Supervisor",
  PH_EXT: "Ej: 12217",
  PH_EMAIL: "Ej: abc@gmail.com",
  PH_DISPLAY_NAME_SIGNUP: "Ej: Hotel California",
  PH_REGION_NAME: "Ej: Estatal",
  PH_COUNTIES: "Ej: Filadelfia",
  PH_ADDRESS_1: "Broadway & E Colfax Ave",
  PH_ADDRESS_2: "Civic Center Park",
  PH_CITY: "Denver",
  PH_COUNTRY: "Seleccionar País",
  PH_SERVICE_DETAILS:
    "Ej: Despensa de Alimentos, Albergue de Emergencia, Prueba de VIH, Ropa, Capacitación GED...",
  PH_TOTAL_BED: "1 o más",
  PH_AVAILABLE_BEDS: "0 o más",
  PH_AGE_GROUP: "Ej: 18+, 17-21, 24 y menores, Todas las Edades, ...",
  PH_FULL_NAME: "Ej: Nombre Completo",
  PH_COORDINATOR_NAME: "Ej: Centro para Escuelas y Comunidades",
  LANGUAGE: "Idioma: Español",
  LANGUAGE_LABEL: "Idioma",
  LANGUAGE_ENGLISH: "Inglés",
  LANGUAGE_SPANISH: "Español"
};
